.container{
    height: 100vh;
}

.card{
    border-radius: 30px !important;
    padding: 20px;
    /*margin-top: 240px;*/
}

.title{
    font-weight: bold !important;
    margin-bottom: -12px !important;
}

.btn{
    border-radius: 15px!important;
    padding: 10px !important;
}